<template>
  <div
    v-show="isShow"
    class="flex py-4 px-1 flex-wrap justify-center items-center"
    style="gap: 6px"
  >
    <div
      v-for="(sumOpt, val) of info"
      :key="sumOpt"
      class="w-12 flex flex-col justify-center items-center gap-2 text-sm rounded-lg h-16"
      :class="
        Object.keys(betItems).includes(sumOpt.val) ? 'active' : 'bg-[#909093]'
      "
      @click="serBetItems(sumOpt.val)"
    >
      {{ sumOpt.title }}
      <p>{{ sumOpt.odds }}</p>
    </div>
    <p class="text-[#E21818] text-sm tePxt-center">
      *投注大小时若开出总和11时将返还本金
    </p>
  </div>
</template>
<script setup>
import { computed, ref, watch } from 'vue';
const props = defineProps(['isShow', 'info', 'isCancel']);
const emits = defineEmits(['update:betItems', 'canceled']);
const betItems = ref({});
const serBetItems = (e) => {
  if (!!betItems.value[e]) {
    delete betItems.value[e];
  } else {
    betItems.value[e] = 1;
  }
  emits('update:betItems', betItems.value, 'FirstSecondSumMethod');
};
watch(
  () => props.isCancel,
  (newVal) => {
    if (newVal) {
      betItems.value = {};
      emits('canceled');
    }
  }
);
</script>
<style scoped>
.active {
  background: linear-gradient(180deg, #3ac3fb, #0875a4);
  color: #000;
}
</style>
