<template>
  <div class="relative w-full h-screen bg-black overflow-y-scroll game-bg">
    <div class="flex items-center justify-between px-3 py-4 bg-black">
      <div class="flex items-center gap-3">
        <div class="w-[2px] h-5 line" />
        <p class="text-lg">{{ gameName + ' ' + route.params.time + '分' }}</p>
      </div>
      <router-link to="/game-list">
        <van-icon name="cross" size="24px" color="#909093"
      /></router-link>
    </div>
    <div
      class="border-2 border-[#24B6E4] m-4 rounded-xl p-2 text-sm leading-6 bg-[#000D30] bg-opacity-60"
    >
      <div class="flex items-center gap-3">
        <p>
          帐号：<span class="text-[#24B6E4]">{{ userInfo.username }}</span>
        </p>
        <p>
          余额：<span class="text-[#24B6E4]">{{
            +userInfo.last_point || '0.00'
          }}</span>
        </p>
        <van-icon @click="get_user_info" name="replay" />
      </div>
      <p>
        当前投注期数：<span class="text-[#24B6E4]">{{
          roomInfo.current_serial_info?.serial_number || '开奖中'
        }}</span>
      </p>
      <p>
        封盘倒数：<span class="text-[#FFC107]">{{
          roomInfo.current_serial_info?.counter
        }}</span>
        秒
      </p>
    </div>
    <div class="px-4 mb-4 flex items-center justify-center gap-5">
      <div
        class="border border-[#24B6E4] bg-[#000D30] bg-opacity-60 rounded-full flex items-center gap-2 py-2 px-3 text-xs"
        @click="historyShow = true"
      >
        <img src="@/assets/icons/game/clock.svg" />
        近期开奖
      </div>
      <div
        class="border border-[#24B6E4] bg-[#000D30] bg-opacity-60 rounded-full flex items-center gap-2 py-2 px-3 text-xs"
        @click="recordShow = true"
      >
        <img src="@/assets/icons/game/coin.svg" />
        投注记录
      </div>
      <div
        class="border border-[#24B6E4] bg-[#000D30] bg-opacity-60 rounded-full flex items-center gap-2 py-2 px-3 text-xs"
        @click="analyzeShow = true"
      >
        <img src="@/assets/icons/game/star.svg" />
        排名分析
      </div>
    </div>
    <div
      class="shadow-blue border-2 border-[#24B6E4] bg-black mx-4 flex flex-col justify-center items-center rounded-xl overflow-hidden"
    >
      <div
        class="py-3 border-b border-[#24B6E4] w-full flex items-center justify-around"
      >
        <p class="text-xs whitespace-nowrap transform scale-90">
          今日已开：<span class="text-[#FFC107]">{{
            roomInfo.serial_open_today
          }}</span
          >期，剩余：<span class="text-[#FFC107]">{{
            roomInfo.serial_remaining_today
          }}</span
          >期
        </p>
        <p class="text-xs whitespace-nowrap transform scale-90">
          正在开奖期数：<span class="text-[#FFC107]">{{ lastOpenNumber }}</span>
        </p>
      </div>
      <iframe
        v-if="route.params.type === 'car'"
        ref="iframeDom"
        src="/animation/racerar/index.html"
        autoplay="false"
        muted="false"
        frameborder="0"
        :width="currentWidth"
        :height="currentWidth * 0.479"
      ></iframe>
      <iframe
        v-else
        ref="iframeDom"
        src="/animation/raceboat/index.html"
        autoplay="false"
        muted="false"
        frameborder="0"
        :width="currentWidth"
        :height="currentWidth * 0.479"
      ></iframe>
      <div
        class="py-3 w-full border-t border-[#24B6E4] text-center flex items-center justify-evenly"
      >
        <p class="text-xs whitespace-nowrap">
          注数：<span class="text-[#24B6E4]">{{ betTotalInfo.bet }}</span>
        </p>
        <p class="text-xs whitespace-nowrap">
          投注金额：<span class="text-[#24B6E4]">{{
            betTotalInfo.bet * inputCount || '0'
          }}</span>
        </p>
        <p class="text-xs whitespace-nowrap">
          赔率：<span class="text-[#24B6E4]">
            {{
              betTotalInfo.odds_min === betTotalInfo.odds_max
                ? betTotalInfo.odds_min
                : betTotalInfo.odds_min + '~' + betTotalInfo.odds_max
            }}</span
          >
        </p>
        <p class="text-xs whitespace-nowrap">
          可赢金额：<span class="text-[#24B6E4]">{{
            NP.round(betTotalInfo.win, 2)
          }}</span>
        </p>
      </div>
    </div>
    <div class="relative">
      <div
        v-show="isCloseBet"
        class="bg-black absolute top-2 bg-opacity-80 w-full h-full rounded-xl"
      >
        <p class="text-center mt-40 text-[#24B6E4]">关盘中，请稍后</p>
      </div>
      <div class="py-4 flex items-center justify-center flex-wrap gap-1">
        <div
          class="w-[5.2rem] text-xs py-2 text-center border-2 border-[#24B6E4] bg-[#000D30] bg-opacity-60 rounded-full"
          :class="{ 'shadow-blue': gameType === 0 }"
          @click="gameType = 0"
        >
          名次-双面盘
        </div>
        <div
          class="w-[5.2rem] text-xs py-2 text-center border-2 border-[#24B6E4] bg-[#000D30] bg-opacity-60 rounded-full"
          :class="{ 'shadow-blue': gameType === 2 }"
          @click="gameType = 2"
        >
          名次-定位膽
        </div>
        <div
          class="w-[5.2rem] text-xs py-2 text-center border-2 border-[#24B6E4] bg-[#000D30] bg-opacity-60 rounded-full"
          :class="{ 'shadow-blue': gameType === 1 }"
          @click="gameType = 1"
        >
          冠亚军和值
        </div>
        <div
          class="w-[5.2rem] text-xs py-2 text-center border-2 border-[#24B6E4] bg-[#000D30] bg-opacity-60 rounded-full"
          :class="{ 'shadow-blue': gameType === 3 }"
          @click="gameType = 3"
        >
          冠亞軍組合
        </div>
      </div>
      <div
        class="border-2 border-[#24B6E4] bg-[#000D30] bg-opacity-60 shadow-blue rounded-xl mx-4"
      >
        <!-- <component :is="betItem[gameType]" @update:betItems="setBetOrders" /> -->

        <DoubleFace
          :isShow="gameType === 0"
          :isCancel="isCancel"
          :info="roomInfo.bet_info?.DoubleSideMethod"
          @update:betItems="setBetOrders"
          @canceled="isCancel = false"
        />
        <SumBet
          :isShow="gameType === 1"
          :isCancel="isCancel"
          :info="roomInfo.bet_info?.FirstSecondSumMethod"
          @update:betItems="setBetOrders"
          @canceled="isCancel = false"
        />
        <Gall
          :isShow="gameType === 2"
          :isCancel="isCancel"
          :info="roomInfo.bet_info?.LocateGallbladder"
          @update:betItems="setBetOrders"
          @canceled="isCancel = false"
        />
        <Group
          :isShow="gameType === 3"
          :isCancel="isCancel"
          :info="roomInfo.bet_info?.TopThreeCombineMethod"
          @update:betItems="setBetOrders"
          @canceled="isCancel = false"
        />
      </div>
      <div class="flex items-center justify-evenly px-4 pt-4 h-20">
        <img
          v-for="coin of coinList"
          :key="coin"
          class="rounded-full"
          :class="{ 'shadow-blue-coin': +inputCount === coin }"
          :src="
            require(`@/assets/icons/game/${
              +inputCount === coin ? `${coin}-a` : coin
            }.svg`)
          "
          @click="inputCount = NP.plus(inputCount, coin)"
        />
      </div>
      <div class="flex items-center justify-center gap-2 my-4">
        <p>下注金额：</p>
        <input
          v-model="inputCount"
          type="text"
          class="bg-[#000D30] text-lg font-semibold bg-opacity-60 w-[200px] rounded-full leading-10 px-5 border border-[#24B6E4]"
        />
      </div>
      <div class="flex items-center gap-2 px-4 mb-4">
        <div
          class="bg-black border border-[#24B6E4] flex-1 rounded-2xl py-4 text-center"
          @click="(isCancel = true), (betOrders = {}), (inputCount = 0)"
        >
          取消
        </div>
        <div
          class="btn-blue flex-1 rounded-2xl py-4 text-center border border-[#24B6E4]"
          @click="submit"
        >
          确定下注
        </div>
      </div>
      <div
        v-show="Object.keys(betOrders).length"
        class="mx-4 mb-4 p-4 bg-[#000D30] bg-opacity-60 border-2 border-[#24B6E4] rounded-xl text-sm leading-6"
      >
        <template v-for="(order, key) of betOrders" :key="key">
          <p>{{ gameKeys(key) }}:</p>
          <template
            v-if="
              key !== 'FirstSecondSumMethod' && key !== 'FirstSecondGroupMethod'
            "
            v-for="(item, itemKey) of order"
            :key="key + itemKey"
          >
            <p>
              {{ rankingText(+itemKey) }}：
              <span
                v-for="(num, index) of item"
                :key="key + itemKey + num.title"
                class="text-[#FFC107]"
              >
                {{ roomInfo.bet_info[key][index]?.title
                }}<span class="px-1"></span>
              </span>
            </p>
          </template>
          <span
            v-else
            v-for="(num, index) of order"
            :key="key + index"
            class="text-[#FFC107]"
          >
            {{ roomInfo.bet_info[key][index]?.title }}
            <span class="pr-1"></span>
          </span>
        </template>
      </div>
    </div>
    <!-- <div class="board">
      <button id="setRank">设置初始画面排名</button>
      <button id="start" @click="test">开始</button>
    </div> -->
  </div>
  <audio ref="audioRef" class="hidden" controls></audio>
  <audio ref="winAudio" class="hidden" controls></audio>
  <History :isShow="historyShow" @close="historyShow = false" />
  <Record
    :isShow="recordShow"
    :info="roomInfo.bet_info"
    @close="recordShow = false"
  />
  <Analyze :isShow="analyzeShow" @close="analyzeShow = false" />
</template>

<script setup>
import { useRoute } from 'vue-router';
import { computed, ref, onMounted, onBeforeUnmount, nextTick } from 'vue';
import SumBet from '../components/Games/Sum.vue';
import DoubleFace from '../components/Games/DoubleFace.vue';
import Gall from '../components/Games/Gall.vue';
import Group from '../components/Games/Group.vue';
import History from '../components/Games/History.vue';
import Record from '../components/Games/Record.vue';
import Analyze from '../components/Games/Analyze.vue';
import { useStore } from 'vuex';
import { get_games_info, set_game_bet } from '@/api/game';
import { get_user_info } from '@/api/main';
import NP from 'number-precision';
import { showFailToast } from 'vant';

let timer;
let getNewInfoTimer;
const audioRef = ref(null);
const winAudio = ref(null);
const route = useRoute();
const store = useStore();
const historyShow = ref(false);
const recordShow = ref(false);
const analyzeShow = ref(false);
const iframeDom = ref(null);
const gameType = ref(0);
const inputCount = ref(0);
const coinList = ref([10, 50, 100, 1000, 10000]);
const currentWidth = ref(window.innerWidth - 32);
const betOrders = ref({});
const roomInfo = ref({});
const isCancel = ref(false);
const userInfo = computed(() => store.state.userInfo);
const isNewOpen = ref(false);
const lastOpenNumber = ref(0);
const isCloseBet = ref(false);
const callInfoCount = ref(0);
const gameName = computed(() => {
  if (route.params.type === 'car') return '赛车';
  else return '赛船';
});
onMounted(async () => {
  await getRoomInfo();
  setLastTimer();
});
const getRoomInfo = async () => {
  if (timer) {
    clearInterval(timer);
  }
  const res = await get_games_info({
    id: route.params.id,
  });
  if (res) {
    roomInfo.value = res;
    if (!lastOpenNumber.value) {
      lastOpenNumber.value = res.current_serial_info.serial_number;
    }
    if (lastOpenNumber.value === res.last_serial_info.serial_number) {
      clearInterval(getNewInfoTimer);
      isNewOpen.value = true;
      startAnimation(res.last_serial_info.open_codes);
      setLastTimer();
      isCloseBet.value = false;
      setTimeout(async () => {
        await get_user_info();
      }, 1000 * 2);
      setTimeout(() => {
        lastOpenNumber.value = res.current_serial_info.serial_number;
      }, 1000 * 35);
    }
  }
  for (const key in roomInfo.value.bet_info.DoubleSideMethod) {
    roomInfo.value.bet_info.DoubleSideMethod[key].val = key;
  }
  for (const key in roomInfo.value.bet_info.FirstSecondSumMethod) {
    roomInfo.value.bet_info.FirstSecondSumMethod[key].val = key;
  }
  for (const key in roomInfo.value.bet_info.FirstSecondGroupMethod) {
    roomInfo.value.bet_info.FirstSecondGroupMethod[key].val = key;
  }
  for (const key in roomInfo.value.bet_info.LocateGallbladder) {
    roomInfo.value.bet_info.LocateGallbladder[key].val = key;
  }
  window.addEventListener('message', (e) => {
    if (typeof e.data === 'string') {
      if (JSON.parse(e.data).event === 'ready') {
        setLastOpenNumber(res.last_serial_info?.open_codes);
      }
    }
  });
};
onBeforeUnmount(() => {
  clearInterval(timer);
  clearInterval(getNewInfoTimer);
});
const setBetOrders = (e, name) => {
  betOrders.value[name] = e;
};
const handlePlay = () => {
  // 播放 暂停也同理
  audioRef.value.src = require('@/assets/audio/bgm.mp3');
  audioRef.value?.play();
  audioRef.value.addEventListener('ended', function () {
    winAudio.value.src = require('@/assets/audio/victory.mp3');
    winAudio.value?.play();
  });
};
const submit = async () => {
  if (!+inputCount.value) {
    showFailToast('请输入投注金额');
    return;
  }
  const res = await set_game_bet({
    game_id: route.params.id,
    bet_price: inputCount.value,
    bet_data: betOrders.value,
  });
  if (res) {
    isCancel.value = true;
    betOrders.value = {};
    inputCount.value = 0;
  }
};
const startAnimation = (e) => {
  iframeDom.value.contentWindow.postMessage(
    JSON.stringify({
      action: 'start',
      data: e.join(' '),
    })
  );
  handlePlay();
};
const setLastOpenNumber = (e) => {
  iframeDom.value.contentWindow.postMessage(
    JSON.stringify({
      action: 'set-rank',
      data: e.join(' '),
    })
  );
};
const setLastTimer = () => {
  clearInterval(timer);
  timer = setInterval(async () => {
    if (roomInfo.value.current_serial_info.counter < 1) {
      isCloseBet.value = true;
      clearInterval(timer);
      if (!isNewOpen.value) {
        getNewInfoTimer = setInterval(async () => {
          if (callInfoCount.value >= 10) {
            clearInterval(getNewInfoTimer);
            callInfoCount.value = 0;
          }
          await getRoomInfo();
          callInfoCount.value += 1;
        }, 1000);
      } else {
        isNewOpen.value = false;
        clearInterval(getNewInfoTimer);
      }
    } else {
      isNewOpen.value = false;
      roomInfo.value.current_serial_info.counter--;
      clearInterval(getNewInfoTimer);
      callInfoCount.value = 0;
    }
  }, 1000);
};
const betTotalInfo = computed(() => {
  let info = {
    bet: 0,
    odds_max: '0',
    odds_min: '0',
    win: 0,
  };
  let oddArr = [];
  for (const i in betOrders.value) {
    for (const x in betOrders.value[i]) {
      if (i !== 'FirstSecondSumMethod' && i !== 'FirstSecondGroupMethod') {
        info.bet += Object.keys(betOrders.value[i][x]).length;
        for (const y in betOrders.value[i][x]) {
          let odd = 0;
          if (
            i === 'DoubleSideMethod' &&
            (roomInfo.value.bet_info[i][y].val === 'big' ||
              roomInfo.value.bet_info[i][y].val === 'small')
          ) {
            console.log('flag', roomInfo.value.bet_info[i][y].odds);
            odd = 0.98;
          } else {
            odd = roomInfo.value.bet_info[i][y].odds;
          }
          oddArr.push(odd);
          info.win += (1 + odd) * (inputCount.value || 0);
        }
      } else {
        info.bet++;
        oddArr.push(roomInfo.value.bet_info[i][x].odds);
        info.win +=
          (1 + roomInfo.value.bet_info[i][x].odds) * (inputCount.value || 0);
      }
    }
  }
  info.odds_max = oddArr.length ? Math.max(...oddArr) : 0;
  info.odds_min = oddArr.length ? Math.min(...oddArr) : 0;
  return info;
});
const gameKeys = (e) => {
  if (e === 'DoubleSideMethod') {
    return '名次-双面盘';
  } else if (e === 'FirstSecondSumMethod') {
    return ' 冠亚军和值 ';
  } else if (e === 'FirstSecondGroupMethod') {
    return '冠亞軍組合';
  } else {
    return '名次-定位膽';
  }
};
const rankingText = (e) => {
  switch (e) {
    case 1:
      return '冠军';
    case 2:
      return '亚军';
    case 3:
      return '季军';
    case 4:
      return '四名';
    case 5:
      return '五名';
    case 6:
      return '六名';
    case 7:
      return '七名';
    case 8:
      return '八名';
    case 9:
      return '九名';
    case 10:
      return '十名';
  }
};
</script>

<style lang="scss" scoped>
.line {
  background: linear-gradient(180deg, #ff2a6d, #ff2aaf);
}

.btn-blue {
  background: linear-gradient(180deg, #1da3cd, #25bae9);
}

.game-bg {
  background-image: url('~@/assets/images/game-bg.png');
}

.shadow-blue {
  box-shadow: 0 0 10px 1px rgba(21, 186, 236, 0.8);
}

.shadow-blue-coin {
  box-shadow: 0 0 10px rgba(21, 186, 236, 0.8);
}
</style>
