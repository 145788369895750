<template>
    <van-popup v-model:show="show" round @close="emits('close')"
        :style="{ padding: '1rem 0', backgroundColor: '#2B2633', width: '100%' }">
        <div class="w-full h-full overflow-y-scroll">
            <div class="flex items-center justify-between px-3 pb-4 border-b border-white border-opacity-10">
                <div class="flex items-center gap-3">
                    <div class="w-[2px] h-5 line" />
                    <p class="text-lg">排名分析</p>
                </div>
                <van-icon @click="close" name="cross" size="24px" color="#909093" />
            </div>
            <div class="m-4 border border-[#24B6E4] rounded-xl py-4 bg-[#000D30] bg-opacity-60">
                <div class="flex items-center justify-center gap-4 text-sm">
                    <!-- <div class="rounded-md leading-6 px-3"
                        :class="activeType === 0 ? 'bg-[#24B6E4] text-black ' : 'bg-[#07232F] border border-[#24B6E4] text-[#24B6E4]'"
                        @click="activeType = 0">
                        当前遗漏
                    </div> -->
                    <div class="rounded-md leading-6 px-3"
                        :class="activeType === 1 ? 'bg-[#24B6E4] text-black ' : 'bg-[#07232F] border border-[#24B6E4] text-[#24B6E4]'"
                        @click="activeType = 1">
                        近100期出现
                    </div>
                </div>
                <div class="grid grid-cols-5 gap-y-3 py-4 text-[13px]">
                    <div v-for="item of 10" :key="item" class="text-center">
                        <span :class="{ 'border-b border-[#24B6E4] text-[#24B6E4]': activeNumber === item }"
                            @click="activeNumber = item">
                            {{ rankingText(item) }}
                        </span>
                    </div>
                </div>
                <div class="flex flex-wrap items-center justify-center gap-y-4 py-4 text-[13px]">
                    <div v-for="(count, key) of list[activeNumber]" :key="key + ':' + count"
                        class="w-1/4 flex flex-col justify-center items-center gap-3">
                        <img class="w-10" :src="require(`@/assets/icons/game/history/balls-${key}.svg`)">
                        <p class="shadow-blue border border-[#24B6E4] text-xs px-4 leading-6 rounded-md">
                            {{ count }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </van-popup>
</template>
<script setup>
import { ref, computed, watch } from 'vue'
import { get_serial_analyze } from '@/api/game'
const props = defineProps({
    isShow: {
        type: Boolean,
        default: false
    }
})
const emits = defineEmits(['close'])
const list = ref([])
const show = ref(false)
const activeType = ref(1)
const activeNumber = ref(1)
const close = () => {
    show.value = false
    emits('close')
}
const rankingText = (e) => {
    switch (e) {
        case 1:
            return '冠军'
        case 2:
            return '亚军'
        case 3:
            return '季军'
        case 4:
            return '四名'
        case 5:
            return '五名'
        case 6:
            return '六名'
        case 7:
            return '七名'
        case 8:
            return '八名'
        case 9:
            return '九名'
        case 10:
            return '十名'
    }
}
watch(
    () => props.isShow,
    async (newVal) => {
        show.value = props.isShow
        if (newVal) {
            const res = await get_serial_analyze()
            if (res) {
                list.value = res
            }
        }
    },
    { deep: true }
)
</script>
<style lang="scss" scoped>
.line {
    background: linear-gradient(180deg, #FF2A6D, #FF2AAF);
}

.shadow-blue {
    box-shadow: 0 0 5px rgba(21, 186, 236, 0.6);
}
</style>