<template>
  <div v-show="isShow">
    <div v-for="(item, index) of 10" :key="item" class="flex items-center">
      <p class="leading-[2.75rem] text-center w-[3.5rem] text-xs">
        {{ rankingText(item) }}
      </p>
      <div
        class="flex-1 overflow-x-scroll flex items-center gap-3 pr-3 py-1 mr-2"
        :class="{ 'border-b border-[#24B6E4] border-opacity-50': index !== 9 }"
      >
        <template v-for="(opt, key) of info" :key="item + ':' + key">
          <p
            v-if="opt.title <= 10"
            class="leading-7 text-sm rounded-full flex items-center justify-center"
            :class="[
              betActive.includes(item + ':' + key) ? 'active' : 'bg-[#909093]',
              opt.title < 10 ? 'px-[0.65rem]' : 'px-2',
            ]"
            @click="serBetItems(item, key)"
          >
            {{ opt.title }}
          </p>
          <p
            v-else
            class="bg-[#004C9B] px-[0.3rem] leading-6 text-sm rounded-md flex items-center justify-center"
            @click="setBetGroup(item, key)"
          >
            {{ opt.title }}
          </p>
        </template>
        <p
          class="bg-[#004C9B] px-[0.3rem] leading-6 text-sm rounded-md flex items-center justify-center"
          @click="cleanItem(item)"
        >
          清
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
const props = defineProps(['isShow', 'info', 'isCancel']);
const emits = defineEmits(['update:betItems', 'canceled']);
const betItems = ref({});
const betActive = ref([]);
const serBetItems = (betTitle, e) => {
  if (betItems.value[betTitle]) {
    if (!!betItems.value[betTitle][e]) {
      delete betItems.value[betTitle][e];
      if (!Object.keys(betItems.value[betTitle]).length) {
        delete betItems.value[betTitle];
      }
      const activeIndex = betActive.value.findIndex((x) => {
        return x === betTitle + ':' + e;
      });
      betActive.value.splice(activeIndex, 1);
    } else {
      betItems.value[betTitle][e] = 1;
      betActive.value.push(betTitle + ':' + e);
    }
  } else {
    betItems.value[betTitle] = {};
    betItems.value[betTitle][e] = 1;
    betActive.value.push(betTitle + ':' + e);
  }
  emits('update:betItems', betItems.value, 'LocateGallbladder');
};
const cleanItem = (betTitle) => {
  delete betItems.value[betTitle];
  const arr = [...betActive.value];
  arr.map((e) => {
    if (e.indexOf(betTitle) === 0) {
      const index = betActive.value.findIndex((x) => {
        return x === e;
      });
      betActive.value.splice(index, 1);
    }
  });
  emits('update:betItems', betItems.value, 'LocateGallbladder');
};
const setBetGroup = (betTitle, e) => {
  cleanItem(betTitle);
  if (e === 'small') {
    for (let i = 1; i < 6; i++) {
      serBetItems(betTitle, i);
    }
  } else if (e === 'big') {
    for (let i = 6; i <= 10; i++) {
      serBetItems(betTitle, i);
    }
  } else if (e === 'single') {
    for (let i = 1; i <= 10; i++) {
      if (i % 2) {
        serBetItems(betTitle, i);
      }
    }
  } else {
    for (let i = 1; i <= 10; i++) {
      if (i % 2 === 0) {
        serBetItems(betTitle, i);
      }
    }
  }
};
const rankingText = (e) => {
  switch (e) {
    case 1:
      return '冠军';
    case 2:
      return '亚军';
    case 3:
      return '季军';
    case 4:
      return '四名';
    case 5:
      return '五名';
    case 6:
      return '六名';
    case 7:
      return '七名';
    case 8:
      return '八名';
    case 9:
      return '九名';
    case 10:
      return '十名';
  }
};
watch(
  () => props.isCancel,
  (newVal) => {
    if (newVal) {
      betItems.value = {};
      betActive.value = [];
      emits('canceled');
    }
  }
);
</script>
<style scoped>
.active {
  background: linear-gradient(180deg, #3ac3fb, #0875a4);
  color: #000;
}
</style>
