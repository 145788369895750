<template>
  <div v-show="isShow" class="p-4 w-full text-sm">
    <div
      class="grid grid-cols-11 overflow-scroll gap-x-[3.2rem] gap-y-[0.3rem]"
    >
      <div
        v-for="item of 11"
        :key="item"
        class="w-12 leading-8 rounded-full text-center text-white font-medium"
        :class="item === 1 ? 'bg-[#3A88EC]' : 'bg-[#2563B3]'"
      >
        {{ item - 1 }}
      </div>
      <div
        class="w-12 leading-8 rounded-full text-center text-white font-medium bg-[#2563B3]"
      >
        6
      </div>
      <div
        class="w-12 leading-8 rounded-full text-center text-white font-medium bg-[#2563B3]"
      >
        1
      </div>
      <div
        v-for="item of 9"
        :key="item"
        class="w-12 leading-8 rounded-full text-center text-black font-medium"
        @click="serBetItems(`1-${item + 1}`)"
        :class="
          Object.keys(betItems).includes(`1-${item + 1}`)
            ? 'active'
            : 'bg-[#909093]'
        "
      >
        1-{{ item + 1 }}
      </div>
      <div
        class="w-12 leading-8 rounded-full text-center text-black font-medium"
        @click="serBetItems('6-7')"
        :class="
          Object.keys(betItems).includes('6-7') ? 'active' : 'bg-[#909093]'
        "
      >
        6-7
      </div>
      <div
        class="w-12 leading-8 rounded-full text-center text-white font-medium bg-[#2563B3]"
      >
        7
      </div>
      <div
        class="w-12 leading-8 rounded-full text-center text-white font-medium bg-[#2563B3]"
      >
        2
      </div>
      <div
        v-for="item of 8"
        :key="item"
        class="w-12 leading-8 rounded-full text-center text-black font-medium bg-[#868686]"
        @click="serBetItems(`2-${item + 2}`)"
        :class="
          Object.keys(betItems).includes(`2-${item + 2}`)
            ? 'active'
            : 'bg-[#909093]'
        "
      >
        2-{{ item + 2 }}
      </div>
      <div
        class="w-12 leading-8 rounded-full text-center text-black font-medium bg-[#868686]"
        @click="serBetItems('6-8')"
        :class="
          Object.keys(betItems).includes('6-8') ? 'active' : 'bg-[#909093]'
        "
      >
        6-8
      </div>
      <div
        class="w-12 leading-8 rounded-full text-center text-black font-medium bg-[#868686]"
        @click="serBetItems('7-8')"
        :class="
          Object.keys(betItems).includes('7-8') ? 'active' : 'bg-[#909093]'
        "
      >
        7-8
      </div>
      <div
        class="w-12 leading-8 rounded-full text-center text-white font-medium bg-[#2563B3]"
      >
        8
      </div>
      <div
        class="w-12 leading-8 rounded-full text-center text-white font-medium bg-[#2563B3]"
      >
        3
      </div>
      <div
        v-for="item of 7"
        :key="item"
        class="w-12 leading-8 rounded-full text-center text-black font-medium bg-[#868686]"
        @click="serBetItems(`3-${item + 3}`)"
        :class="
          Object.keys(betItems).includes(`3-${item + 3}`)
            ? 'active'
            : 'bg-[#909093]'
        "
      >
        3-{{ item + 3 }}
      </div>
      <div
        class="w-12 leading-8 rounded-full text-center text-black font-medium bg-[#868686]"
        @click="serBetItems('6-9')"
        :class="
          Object.keys(betItems).includes('6-9') ? 'active' : 'bg-[#909093]'
        "
      >
        6-9
      </div>
      <div
        class="w-12 leading-8 rounded-full text-center text-black font-medium bg-[#868686]"
        @click="serBetItems('7-9')"
        :class="
          Object.keys(betItems).includes('7-9') ? 'active' : 'bg-[#909093]'
        "
      >
        7-9
      </div>
      <div
        class="w-12 leading-8 rounded-full text-center text-black font-medium bg-[#868686]"
        @click="serBetItems('8-9')"
        :class="
          Object.keys(betItems).includes('8-9') ? 'active' : 'bg-[#909093]'
        "
      >
        8-9
      </div>
      <div
        class="w-12 leading-8 rounded-full text-center text-white font-medium bg-[#2563B3]"
      >
        9
      </div>
      <div
        class="w-12 leading-8 rounded-full text-center text-white font-medium bg-[#2563B3]"
      >
        4
      </div>
      <div
        v-for="item of 6"
        :key="item"
        class="w-12 leading-8 rounded-full text-center text-black font-medium bg-[#868686]"
        @click="serBetItems(`4-${item + 4}`)"
        :class="
          Object.keys(betItems).includes(`4-${item + 4}`)
            ? 'active'
            : 'bg-[#909093]'
        "
      >
        4-{{ item + 4 }}
      </div>
      <div
        class="w-12 leading-8 rounded-full text-center text-black font-medium bg-[#868686]"
        @click="serBetItems('6-10')"
        :class="
          Object.keys(betItems).includes('6-10') ? 'active' : 'bg-[#909093]'
        "
      >
        6-10
      </div>
      <div
        class="w-12 leading-8 rounded-full text-center text-black font-medium bg-[#868686]"
        @click="serBetItems('7-10')"
        :class="
          Object.keys(betItems).includes('7-10') ? 'active' : 'bg-[#909093]'
        "
      >
        7-10
      </div>
      <div
        class="w-12 leading-8 rounded-full text-center text-black font-medium bg-[#868686]"
        @click="serBetItems('8-10')"
        :class="
          Object.keys(betItems).includes('8-10') ? 'active' : 'bg-[#909093]'
        "
      >
        8-10
      </div>
      <div
        class="w-12 leading-8 rounded-full text-center text-black font-medium bg-[#868686]"
        @click="serBetItems('9-10')"
        :class="
          Object.keys(betItems).includes('9-10') ? 'active' : 'bg-[#909093]'
        "
      >
        9-10
      </div>
      <div
        class="w-12 leading-8 rounded-full text-center text-white font-medium bg-[#2563B3]"
      >
        10
      </div>
      <div
        class="w-12 leading-8 rounded-full text-center text-white font-medium bg-[#2563B3]"
      >
        5
      </div>
      <div
        v-for="item of 5"
        :key="item"
        class="w-12 leading-8 rounded-full text-center text-black font-medium bg-[#868686]"
        @click="serBetItems(`5-${item + 5}`)"
        :class="
          Object.keys(betItems).includes(`5-${item + 5}`)
            ? 'active'
            : 'bg-[#909093]'
        "
      >
        5-{{ item + 5 }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
const props = defineProps(['isShow', 'info', 'isCancel']);
const emits = defineEmits(['update:betItems', 'canceled']);
const betItems = ref({});
const serBetItems = (e) => {
  if (!!betItems.value[e]) {
    delete betItems.value[e];
  } else {
    betItems.value[e] = 1;
  }
  emits('update:betItems', betItems.value, 'FirstSecondGroupMethod');
};
watch(
  () => props.isCancel,
  (newVal) => {
    if (newVal) {
      betItems.value = {};
      emits('canceled');
    }
  }
);
</script>

<style scoped>
.active {
  background: linear-gradient(180deg, #3ac3fb, #0875a4);
  color: #000;
}
</style>
