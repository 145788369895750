<template>
    <div v-show="isShow">
        <div v-for="(item, index) of 10" :key="item" class="flex items-center">
            <p class="leading-[2.75rem] text-center w-[3.5rem] text-xs"> {{ rankingText(item) }}</p>
            <div class="flex-1 overflow-x-scroll flex items-center gap-3 pr-3 py-1 mr-2"
                :class="{ 'border-b border-[#24B6E4] border-opacity-50': index !== 9 }">
                <p v-show="sixToTenOption(item, key)" v-for="(opt, key) of info" :key="item + key"
                    class=" px-2 leading-7 text-sm rounded-full flex items-center justify-center"
                    :class="betActive.includes(item + key) ? 'active' : 'bg-[#909093]'" @click="serBetItems(item, key)">
                    {{ opt.title }}
                </p>
                <!-- <p v-else v-for="(opt2, key2) of info" :key="item + key2"
                    class="bg-[#909093] px-2 leading-7 text-sm rounded-full flex items-center justify-center"
                    :class="betActive.includes(item + key2) ? 'active' : 'bg-[#909093]'" @click="serBetItems(item, key)">
                    {{ opt2.title }}
                </p> -->
                <p class="bg-[#004C9B] px-[0.3rem] leading-6 text-sm rounded-md flex items-center justify-center"
                    @click="cleanItem(item)">
                    清
                </p>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, ref, watch } from 'vue';
const props = defineProps(['isShow', 'info', 'isCancel'])
const emits = defineEmits(['update:betItems', 'canceled'])
const betItems = ref({})
const betActive = ref([])
const serBetItems = (betTitle, e) => {
    if (betItems.value[betTitle]) {
        if (!!betItems.value[betTitle][e]) {
            delete betItems.value[betTitle][e]
            if (!Object.keys(betItems.value[betTitle]).length) {
                delete betItems.value[betTitle]
            }
            const activeIndex = betActive.value.findIndex(x => {
                return x === betTitle + e
            })
            betActive.value.splice(activeIndex, 1)
        } else {
            betItems.value[betTitle][e] = 1
            betActive.value.push(betTitle + e)
        }
    } else {
        betItems.value[betTitle] = {}
        betItems.value[betTitle][e] = 1
        betActive.value.push(betTitle + e)
    }
    emits('update:betItems', betItems.value, 'DoubleSideMethod')
}
const cleanItem = (betTitle) => {
    delete betItems.value[betTitle]
    console.log(betItems.value);
    const arr = [...betActive.value]
    arr.map(e => {
        if (e.indexOf(betTitle) > -1) {
            const index = betActive.value.findIndex(x => x === e)
            betActive.value.splice(index, 1)
        }
    })
    emits('update:betItems', betItems.value, 'DoubleSideMethod')
}
const sixToTenOption = (index, opt) => {
    if (index <= 5) {
        return true
    } else if (index > 5 && opt !== 'dragon' && opt !== 'tiger') {
        return true
    } else {
        return false
    }
}
const rankingText = (e) => {
    switch (e) {
        case 1:
            return '冠军'
        case 2:
            return '亚军'
        case 3:
            return '季军'
        case 4:
            return '四名'
        case 5:
            return '五名'
        case 6:
            return '六名'
        case 7:
            return '七名'
        case 8:
            return '八名'
        case 9:
            return '九名'
        case 10:
            return '十名'
    }
}
watch(
    () => props.isCancel,
    (newVal) => {
        if (newVal) {
            betItems.value = {}
            betActive.value = []
            emits('canceled')
        }
    }
)
</script>
<style scoped>
.active {
    background: linear-gradient(180deg, #3AC3FB, #0875A4);
    color: #000;
}
</style>