<template>
    <van-popup v-model:show="show" round @close="emits('close')"
        :style="{ padding: '1rem 0', backgroundColor: '#2B2633', width: '100%', height: '95%' }">
        <div class="w-full h-full overflow-y-scroll">
            <div class="flex items-center justify-between px-3 pb-4 border-b border-white border-opacity-10">
                <div class="flex items-center gap-3">
                    <div class="w-[2px] h-5 line" />
                    <p class="text-lg">近期开奖</p>
                </div>
                <van-icon @click="close" name="cross" size="24px" color="#909093" />
            </div>
            <div class="py-6 px-4">
                <div v-for="item of list" :key="item.serial"
                    class="border border-[#1DAFFB] rounded-2xl overflow-hidden mb-4">
                    <div class="bg-[#1DAFFB] text-white leading-8 text-sm flex items-center justify-between px-2">
                        <p class="">{{ item.serial }}</p>
                        <p>{{ formatDate(item.open_at, 'yyyy/MM/dd hh:mm:ss') }}</p>
                    </div>
                    <div class="flex items-center justify-around py-2 px-1 bg-[#000D30] bg-opacity-60">
                        <img v-for="no of item.open_codes" :key="no"
                            :src="require(`@/assets/icons/game/history/balls-${no}.svg`)">
                    </div>
                </div>
            </div>
        </div>
    </van-popup>
</template>
<script setup>
import { ref, computed, watch } from 'vue'
import { get_history_serial } from '@/api/game'
import { useRoute } from 'vue-router';
import { formatDate } from '@/utils/date'
const props = defineProps({
    isShow: {
        type: Boolean,
        default: false
    }
})
const emits = defineEmits(['close'])
const route = useRoute()
const list = ref([])
const show = ref(false)
const close = () => {
    show.value = false
    emits('close')
}

watch(
    () => props.isShow,
    async (newVal) => {
        show.value = props.isShow
        if (newVal) {
            const res = await get_history_serial({
                game_id: route.params.id
            })
            if (res) {
                list.value = res
            }
        }
    }
)
</script>
<style lang="scss" scoped>
.line {
    background: linear-gradient(180deg, #FF2A6D, #FF2AAF);
}
</style>