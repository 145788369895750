<template>
  <van-popup
    v-model:show="show"
    round
    @close="emits('close')"
    :style="{
      padding: '1rem 0',
      backgroundColor: '#2B2633',
      width: '100%',
      height: '95%',
    }"
  >
    <div class="w-full h-full overflow-y-scroll">
      <div
        class="flex items-center justify-between px-3 pb-4 border-b border-white border-opacity-10"
      >
        <div class="flex items-center gap-3">
          <div class="w-[2px] h-5 line" />
          <p class="text-lg">投注记录</p>
        </div>
        <van-icon @click="close" name="cross" size="24px" color="#909093" />
      </div>
      <div class="px-4 py-6">
        <div>
          <p class="text-sm text-[#909093]">期号</p>
          <input
            v-model="form.serial_number"
            class="w-full px-4 text-sm placeholder-[#909093] bg-[#1C1316] py-2 leading-6 rounded-lg mt-2"
            placeholder="请输入期号"
          />
        </div>
        <div class="flex items-center gap-2 mt-4">
          <div class="flex-1">
            <p class="text-sm text-[#909093]">开始日期</p>
            <div
              @click="showaDatePickerStart = true"
              class="w-full px-4 text-sm bg-[#1C1316] py-2 h-9 rounded-lg mt-2"
              :class="!datePickerStartText ? 'text-[#909093]' : 'text-white'"
            >
              {{ datePickerStartText || '请选择日期' }}
            </div>
          </div>
          <div class="flex-1">
            <p class="text-sm text-[#909093]">结束时间</p>
            <div
              @click="showaDatePickerEnd = true"
              class="w-full px-4 text-sm placeholder-[#909093] bg-[#1C1316] py-2 h-9 leading-6 rounded-lg mt-2"
              :class="!datePickerEndText ? 'text-[#909093]' : 'text-white'"
            >
              {{ datePickerEndText || '请选择日期' }}
            </div>
          </div>
        </div>
        <div
          class="bg-[#24B6E4] rounded-full text-sm py-3 text-center mt-5"
          @click="getBetRecord(form)"
        >
          搜寻
        </div>
        <div class="rounded-xl border border-[#24B6E4] mt-3 overflow-hidden">
          <div class="h-[300px] overflow-scroll bg-[#000D30] bg-opacity-60">
            <div
              class="bg-[#1DAFFB] text-black py-2 text-[10px] flex items-center whitespace-nowrap px-2 w-[500px]"
            >
              <p class="w-4/12">注单编号/下注时间</p>
              <p class="w-4/12">注单内容</p>
              <p class="w-2/12">下注金额</p>
              <p class="w-2/12">开奖结果</p>
            </div>
            <div class="py-2 text-[10px] whitespace-nowrap px-2 w-[500px]">
              <div
                v-for="(order, index) of list"
                :key="order.id"
                class="flex items-center py-2"
                :class="{
                  'border-b border-[#1DAFFB] border-opacity-20': index !== 10,
                }"
              >
                <div class="w-4/12">
                  <p>{{ order.serial_number }}</p>
                  <p>{{ formatDate(order.created, 'yyyy-MM-dd hh:mm:ss') }}</p>
                </div>
                <div class="w-4/12 whitespace-pre-wrap flex flex-col pr-3">
                  <div v-for="(bet, gameType) of order.bet_data" :key="bet">
                    <p>{{ setGameTypeText(gameType) }}:</p>
                    <template v-for="(detail, key) of order.bet_data[gameType]">
                      <p
                        v-if="
                          gameType !== 'FirstSecondSumMethod' &&
                          gameType !== 'FirstSecondGroupMethod'
                        "
                        :key="`${gameType}${key}`"
                      >
                        {{ rankingText(+key) }}
                        <span
                          v-for="(text, i) of Object.keys(detail)"
                          :key="`${rankingText(+key)}:${BetTitle[text]}`"
                          class="pr-1"
                        >
                          {{ BetTitle[text] }} ({{
                            order.odds_data[gameType][key][text]
                          }})
                          {{ i !== Object.keys(detail).length - 1 ? ', ' : '' }}
                        </span>
                      </p>
                      <span
                        v-else-if="gameType === 'FirstSecondSumMethod'"
                        :key="`FirstSecondSumMethod${key}`"
                        class="pr-1"
                      >
                        {{ BetTitle[key] }} ({{
                          order.odds_data[gameType][key]
                        }})
                      </span>
                      <span
                        v-else
                        :key="`FirstSecondGroupMethod${key}`"
                        class="pr-1"
                      >
                        {{ key }}
                        ({{ order.odds_data[gameType][1] }})
                      </span>
                    </template>
                  </div>
                </div>
                <div class="w-2/12">
                  <p>{{ +order.pay_total_point }}元</p>
                </div>
                <div class="w-2/12">
                  <p>
                    {{
                      +order.profit_total_point >= 0
                        ? '+' + +order.profit_total_point
                        : +order.profit_total_point
                    }}元
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-popup>
  <van-popup v-model:show="showaDatePickerStart" round position="bottom">
    <van-date-picker
      title="选择开始日期"
      :max-date="new Date(new Date().toLocaleDateString())"
      @confirm="setDatePickerValue($event, 'start')"
      @cancel="(showaDatePickerStart = false), (datePickerText = [])"
    />
  </van-popup>
  <van-popup v-model:show="showaDatePickerEnd" round position="bottom">
    <van-date-picker
      title="选择结束日期"
      :max-date="new Date(new Date().toLocaleDateString())"
      @confirm="setDatePickerValue($event, 'end')"
      @cancel="(showaDatePickerEnd = false), (datePickerText = [])"
    />
  </van-popup>
</template>
<script setup>
import { ref, computed, watch } from 'vue';
import { get_game_orders } from '@/api/game';
import { formatDate } from '@/utils/date';
import BetTitle from '../../utils/betOptionsText';
const props = defineProps({
  isShow: {
    type: Boolean,
    default: false,
  },
  info: {
    type: Object,
    default: {},
  },
});
const emits = defineEmits(['close']);
const showaDatePickerStart = ref(false);
const showaDatePickerEnd = ref(false);
const datePickerStartText = ref('');
const datePickerEndText = ref('');
const show = ref(false);
const form = ref({
  serial_number: '',
  start_time: '',
  end_time: '',
});
const list = ref([]);
const setDatePickerValue = (e, type) => {
  console.log(e.selectedValues);
  if (type === 'start') {
    datePickerStartText.value = '';
    form.value.start_time =
      new Date(e.selectedValues.join('/')).getTime() / 1000;
    showaDatePickerStart.value = false;
    datePickerStartText.value = e.selectedValues.join('-');
  } else {
    datePickerEndText.value = '';
    form.value.end_time = new Date(e.selectedValues.join('/')).getTime() / 1000;
    showaDatePickerEnd.value = false;
    datePickerEndText.value = e.selectedValues.join('-');
  }
};
const close = () => {
  show.value = false;
  emits('close');
};
const getBetRecord = async (payload) => {
  const res = await get_game_orders(payload);
  if (res) {
    list.value = res;
  }
};
const setGameTypeText = (str) => {
  if (str === 'DoubleSideMethod') {
    return '名次-双面盘';
  } else if (str === 'FirstSecondSumMethod') {
    return ' 冠亚军和值 ';
  } else if (str === 'FirstSecondGroupMethod') {
    return '冠亞軍組合';
  } else {
    return '名次-定位膽';
  }
};
const rankingText = (e) => {
  switch (e) {
    case 1:
      return '冠军';
    case 2:
      return '亚军';
    case 3:
      return '季军';
    case 4:
      return '第四名';
    case 5:
      return '第五名';
    case 6:
      return '第六名';
    case 7:
      return '第七名';
    case 8:
      return '第八名';
    case 9:
      return '第九名';
    case 10:
      return '第十名';
  }
};
watch(
  () => props.isShow,
  async (newVal) => {
    show.value = props.isShow;
    if (newVal) {
      await getBetRecord();
    }
  },
  { deep: true }
);
</script>
<style lang="scss" scoped>
.line {
  background: linear-gradient(180deg, #ff2a6d, #ff2aaf);
}
</style>
